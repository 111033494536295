<template>
  <a-card
    v-resize="hideOverflowTitle"
    class="campaign-overall"
    :title="
      isMobileScreen
        ? 'Overall ' + (mode === 'mention' ? 'mentioned' : mode)
        : ''
    "
  >
    <div v-if="loading" class="loading-wrapper">
      <a-skeleton :loading="loading" active />
    </div>
    <div v-else>
      <a-row class="campaign-overall-row">
        <a-col v-if="!noData" class="campaign-overall-col">
          <a-row class="overall-row overall-header-row" @click="onOverallClick">
            <a-col>
              <div class="overall">
                <div class="overall-icon">
                  <FeatherIcon type="trending-up" />
                </div>
              </div>
            </a-col>
            <a-col class="overall-count">
              <label class="overall-header">Overall</label>
              <label class="overall-count">{{
                convertNumeral(overallCampaign.total)
              }}</label>
              <label class="overall-type">{{ mode }}</label>
            </a-col>
          </a-row>
        </a-col>
        <a-col flex="1">
          <label v-if="noData" class="overall-engagement">{{
            'Overall ' + (mode === 'mention' ? 'mentioned' : mode)
          }}</label>
          <div v-else id="OverallProgressBar" class="overall-prog">
            <label class="overall-engagement">{{
              'Overall ' + (mode === 'mention' ? 'mentioned' : mode)
            }}</label>
            <a-row v-if="overallCampaign.graphData.length > 0" class="prog-row">
              <div class="item-wrapper">
                <div
                  v-for="(overall, index) in overallCampaign.graphData"
                  :key="`${overall.name}-${index}`"
                  :style="{
                    flexBasis: `${overall.percent}%`,
                  }"
                  class="item-row"
                >
                  <div class="prog-bar">
                    <div v-if="overall.percent >= clientWidth / 100">
                      <span
                        v-if="
                          overall.name !== 'facebook' &&
                          overall.name !== 'blockdit'
                        "
                        class="fa-stack"
                      >
                        <FontAwesomeIcon
                          :icon="['fas', 'circle']"
                          :class="`icon-${overall.icon}`"
                        />
                        <img
                          v-if="overall.name === 'website'"
                          class="source-img"
                          :src="
                            require('@/assets/images/source-icon/svg/website.svg')
                          "
                          alt="logo"
                        />
                        <FontAwesomeIcon
                          v-else-if="overall.name === 'pantip'"
                          :icon="['fas', 'comments']"
                        />
                        <FontAwesomeIcon
                          v-else
                          :icon="[
                            'fab',
                            overall.icon === 'twitter'
                              ? 'x-twitter'
                              : overall.icon,
                          ]"
                          class="icon-social"
                        />
                      </span>
                      <img
                        v-else-if="overall.name === 'blockdit'"
                        class="source-img"
                        :src="
                          require('@/assets/images/source-icon/svg/blockdit.svg')
                        "
                        alt="logo"
                      />
                      <FontAwesomeIcon
                        v-else-if="overall.name === 'facebook'"
                        :icon="['fab', overall.icon]"
                        class="icon-social icon-facebook"
                      />
                    </div>
                    <span
                      v-if="index === 0 && overallCampaign.graphData.length > 1"
                      class="prog-segment has-data prog-bar-first"
                      :class="overall.name"
                      :title="`${overall.percent}% (${overall.value})`"
                      @click="onSegmentClick(overall.name)"
                    ></span>
                    <span
                      v-else-if="
                        index + 1 === overallCampaign.graphData.length &&
                        overallCampaign.graphData.length > 1
                      "
                      class="prog-segment has-data prog-bar-last"
                      :class="overall.name"
                      :title="`${overall.percent}% (${overall.value})`"
                      @click="onSegmentClick(overall.name)"
                    ></span>
                    <span
                      v-else-if="
                        index === 0 && overallCampaign.graphData.length === 1
                      "
                      class="prog-segment has-data prog-bar-radius"
                      :class="overall.name"
                      :title="`${overall.percent}% (${overall.value})`"
                      @click="onSegmentClick(overall.name)"
                    ></span>
                    <span
                      v-else
                      class="prog-segment has-data"
                      :class="overall.name"
                      :title="`${overall.percent}% (${overall.value})`"
                      @click="onSegmentClick(overall.name)"
                    ></span>
                    <!-- <span v-if="c.noData" class="prog-segment no-data"> No data </span> -->
                  </div>
                  <div
                    v-if="overall.percent >= clientWidth / 100"
                    class="prog-desc"
                  >
                    <span
                      class="title element"
                      :class="`title-${overall.name}`"
                    >
                      {{ convertNumeral(overall.value) }}
                    </span>
                    <span class="title element percent">
                      {{ ' (' + overall.percent + '%)' }}
                    </span>
                  </div>
                </div>
              </div>
            </a-row>
            <a-row v-else class="prog-row">
              <div class="item-wrapper no-data"></div>
            </a-row>
          </div>
        </a-col>
      </a-row>
      <div v-if="!loading && noData" class="load-wrapper">
        <NoDataFound></NoDataFound>
      </div>
      <Slider v-else>
        <div
          class="campaign-overall-row social-row d-flex"
          data-slider-container="true"
        >
          <div
            v-for="(sentiment, index) in overallCampaign.data"
            :key="`${sentiment.name}-${index}`"
            class="campaign-overall-social-col"
            data-slider-item="true"
          >
            <a-row
              class="social-block"
              :class="`social-block-${sentiment.name}`"
              @click="onSegmentClick(sentiment.name)"
            >
              <a-row class="overall-row overall-social-row">
                <a-col class="social-col">
                  <span
                    v-if="
                      !['facebook', 'blockdit', 'website'].includes(
                        sentiment.name
                      )
                    "
                    class="fa-stack"
                  >
                    <FontAwesomeIcon
                      :icon="['fas', 'circle']"
                      :class="`icon-${sentiment.icon}`"
                    />

                    <FontAwesomeIcon
                      v-if="sentiment.name === 'pantip'"
                      :icon="['fas', 'comments']"
                      class="icon-social"
                    />
                    <FontAwesomeIcon
                      v-else
                      :icon="[
                        'fab',
                        sentiment.icon === 'twitter'
                          ? 'x-twitter'
                          : sentiment.icon,
                      ]"
                      class="icon-social"
                    />
                  </span>
                  <img
                    v-if="sentiment.name === 'website'"
                    :src="
                      require('@/assets/images/source-icon/svg/website.svg')
                    "
                    class="source-img"
                    alt="logo"
                  />
                  <img
                    v-else-if="sentiment.name === 'blockdit'"
                    class="source-img"
                    :src="
                      require('@/assets/images/source-icon/svg/blockdit.svg')
                    "
                    alt="logo"
                  />
                  <FontAwesomeIcon
                    v-else-if="sentiment.name === 'facebook'"
                    :icon="['fab', sentiment.icon]"
                    class="icon-social icon-facebook"
                  />
                </a-col>
                <a-col class="overall-col">
                  <div class="overall-count">
                    <label class="social-name">{{ sentiment.name }}</label>
                  </div>
                </a-col>
              </a-row>
              <a-row class="overall-row">
                <div class="overall-count social-count">
                  <label class="social-value">{{
                    convertNumeral(sentiment.value)
                  }}</label>
                  <label class="social-footer">
                    <span class="social-percent">{{
                      convertValueToPercent(sentiment.percent) + '%'
                    }}</span>
                    <span class="social-mode">{{ mode }}</span>
                  </label>
                </div>
              </a-row>
            </a-row>
          </div>
        </div>
      </Slider>
    </div>
  </a-card>
</template>

<script>
import { toRefs, watch, computed, ref, reactive, watchEffect } from 'vue';
import { useStore } from 'vuex';

import api from '@/services/api';
import helper from '@/services/helper';
import numeral from 'numeral';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import Slider from '@/components/Slider/Slider.vue';
export default {
  name: 'Overall',

  components: {
    NoDataFound,
    Slider,
  },

  props: {
    mode: String,
    filterResult: Object,
    campaignLevel: Number,
  },

  setup(props) {
    const { mode, filterResult, campaignLevel } = toRefs(props);
    const store = useStore();
    const brand = computed(() => store.state.account.brand);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const overallCampaign = reactive({
      graphData: [],
      data: [],
      total: 0,
    });
    const overallGraphDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    const dataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    const totalByMode = reactive({
      engagement: 0,
      mention: 0,
      view: 0,
    });
    let loading = ref(false);

    const noData = computed(() => {
      for (let item of overallCampaign.data) {
        if (item.value !== 0) {
          return false;
        }
      }
      return true;
    });

    const onSegmentClick = (categoryData) => {
      let catData = categoryData;
      if (categoryData === 'twitter') {
        catData = 'X (Twitter)';
      }

      let f = {};
      if (campaignLevel.value) {
        f = {
          payload: {
            title: `Data for "${helper.capitalize(
              catData
            )}" ${helper.capitalize(mode.value)} `,
          },
          criteria: {
            category: filterResult.value.category,
            subCategory: filterResult.value.subCategory,
            time: filterResult.value.time,
            source: [categoryData],
            sort: {
              direction: 'desc',
              field: 'engagement_score',
            },
            highlight: {
              enable: true,
            },
          },
        };
        if (campaignLevel.value > 0) {
          f.criteria['subCategory'].push(
            filterResult.value.subCategory.find(
              (x) => parseInt(x.level) === campaignLevel.value
            )
          );
        } else {
          f.criteria['category'] = filterResult.value.category;
          f.criteria['subCategory'] = filterResult.value.subCategory;
        }
      } else {
        f = {
          payload: {
            title: `Data for "${helper.capitalize(
              catData
            )}" ${helper.capitalize(mode.value)} `,
          },
          criteria: {
            ...filterResult.value,
            source: [categoryData],
            sort: {
              direction: 'desc',
              field: 'engagement_score',
            },
            highlight: {
              enable: true,
            },
          },
        };
      }
      store.dispatch('message/showMessageModal', f);
    };

    const onOverallClick = () => {
      let f = {};
      if (campaignLevel.value) {
        f = {
          payload: {
            title: `Data for Overall ${helper.capitalize(mode.value)} `,
          },
          criteria: {
            category: filterResult.value.category,
            subCategory: filterResult.value.subCategory,
            time: filterResult.value.time,
            sort: {
              direction: 'desc',
              field: 'engagement_score',
            },
            highlight: {
              enable: true,
            },
          },
        };
        if (campaignLevel.value > 0) {
          f.criteria['subCategory'].push(
            filterResult.value.subCategory.find(
              (x) => parseInt(x.level) === campaignLevel.value
            )
          );
        } else {
          f.criteria['category'] = filterResult.value.category;
          f.criteria['subCategory'] = filterResult.value.subCategory;
        }
      } else {
        f = {
          payload: {
            title: `Data for Overall ${helper.capitalize(mode.value)} `,
          },
          criteria: {
            ...filterResult.value,
            sort: {
              direction: 'desc',
              field: 'engagement_score',
            },
            highlight: {
              enable: true,
            },
          },
        };
      }
      store.dispatch('message/showMessageModal', f);
    };

    const hideOverflowTitle = () => {
      const allSegment = document.querySelectorAll('.prog-inner-element');
      if (allSegment) {
        allSegment.forEach((obj) => {
          if (obj.offsetWidth > obj.parentElement.offsetWidth) {
            obj.style.visibility = 'hidden';
          } else {
            obj.style.visibility = 'visible';
          }
        });
      }
    };

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    const getOverall = async (fv) => {
      if (fv) {
        const result = await api
          .getCampaignPerformanceOverall(fv, brand.value)
          .catch(() => {
            console.error('History api call error', result);
          });
        if (result.message && result.message.data) {
          clearData();
          for (let overall of result.message.data) {
            totalByMode.engagement += overall['engagement'];
            totalByMode.mention += overall['mention'];
            totalByMode.view += overall['view'];
            const engagement = {
              name: overall.source,
              icon: overall.source,
              value: overall['engagement'],
              percent: numeral(overall['engagementPercent'] * 100).format('0'),
            };
            const mention = {
              name: overall.source,
              icon: overall.source,
              value: overall['mention'],
              percent: numeral(overall['mentionPercent'] * 100).format('0'),
            };
            const view = {
              name: overall.source,
              icon: overall.source,
              value: overall['view'],
              percent: numeral(overall['viewPercent'] * 100).format('0'),
            };

            dataByMode.engagement.push(engagement);
            dataByMode.view.push(view);
            dataByMode.mention.push(mention);

            if (engagement.percent !== '0') {
              overallGraphDataByMode.engagement.push(engagement);
            }
            if (mention.percent !== '0') {
              overallGraphDataByMode.mention.push(mention);
            }
            if (view.percent !== '0') {
              overallGraphDataByMode.view.push(view);
            }
          }
          overallCampaign.graphData = overallGraphDataByMode[mode.value].sort(
            (a, b) => {
              if (a.value > b.value) return -1;
              if (a.value < b.value) return 1;
              return 0;
            }
          );
          overallCampaign.data = dataByMode[mode.value].sort((a, b) => {
            if (a.value > b.value) return -1;
            if (a.value < b.value) return 1;
            return 0;
          });
          overallCampaign.total = totalByMode[mode.value];
        }
      }
    };

    const init = async (fv) => {
      loading.value = true;
      await getOverall(fv);
      loading.value = false;
      hideOverflowTitle();
    };

    const convertNumeral = (value) => {
      return helper.numeral(value);
    };

    const convertValueToPercent = (value) => {
      return numeral(value).format('0.00');
    };

    const clearData = () => {
      overallCampaign.graphData = [];
      overallCampaign.data = [];
      overallCampaign.total = 0;

      overallGraphDataByMode.engagement = [];
      overallGraphDataByMode.mention = [];
      overallGraphDataByMode.view = [];

      dataByMode.engagement = [];
      dataByMode.mention = [];
      dataByMode.view = [];

      totalByMode.engagement = 0;
      totalByMode.mention = 0;
      totalByMode.view = 0;
    };

    const clientWidth = computed(() => {
      return window.innerHeight;
    });

    watch(
      () => mode.value,
      () => {
        overallCampaign.graphData = overallGraphDataByMode[mode.value].sort(
          (a, b) => {
            if (a.value > b.value) return -1;
            if (a.value < b.value) return 1;
            return 0;
          }
        );
        overallCampaign.data = dataByMode[mode.value].sort((a, b) => {
          if (a.value > b.value) return -1;
          if (a.value < b.value) return 1;
          return 0;
        });
        overallCampaign.total = totalByMode[mode.value];
      }
    );

    watchEffect(() => {
      init(filterResult.value);
    });

    return {
      onSegmentClick,
      hideOverflowTitle,
      selectedModeStyle,
      unSelectedModeStyle,
      convertNumeral,
      overallCampaign,
      dataByMode,
      onOverallClick,
      loading,
      isMobileScreen,
      clientWidth,
      overallGraphDataByMode,
      numeral,
      convertValueToPercent,
      noData,
    };
  },
};
</script>

<style lang="scss" scoped>
.campaign-overall {
  .loading-wrapper {
    min-height: 270px;
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .campaign-overall-row {
    margin-bottom: 13px;
    padding-bottom: 25px;
    border-bottom: 1px solid #f1f2f6;
    align-items: center;
    .campaign-overall-col {
      width: fit-content;
      margin-right: 30px;
    }
    .overall-header-row {
      cursor: pointer;
      min-width: 310px;
      padding: 20px;
      background: #f8f9fb;
      border: 1px solid #f1f2f6;
      border-radius: 10px;
    }
    .overall-prog {
      height: 100%;
    }
    .overall-row {
      width: fit-content;
      .overall {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 10px;
        .overall-icon {
          width: 56px;
          height: 56px;
          margin-right: 12px;
          background: rgba(51, 113, 255, 0.1);
          border-radius: 10px;
          display: inline-flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          i {
            width: 24px;
            height: 24px;
            font-size: 24px;
            line-height: 24px !important;
            color: #3371ff;
          }
        }
      }
      .overall-count {
        display: block;
        margin: auto 0;
        .overall-header {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #5a5f7d;
        }
        .overall-count {
          font-weight: 700;
          font-size: 30px;
          line-height: 39px;
          color: #272b41;
        }
        .overall-type {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #9299b8;
          text-transform: capitalize;
        }
      }
      .overall-img {
        width: 35px;
        height: 35px;
        margin-right: 24px;
      }
      .img-youtube {
        width: 50px;
        height: 35px;
      }
    }
    .overall-social-row {
      height: 24px;
      margin: auto 0 16px;
    }
    .overall-engagement {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #272b41;
      text-transform: capitalize;
    }
    .prog-row {
      min-height: 68px;
      justify-content: flex-start;
      align-items: center;
      margin-top: 12px;
      .item-wrapper.no-data {
        height: 12px;
        background-color: #f8f9fb;
        border-radius: 8px;
      }
      .item-wrapper {
        width: 100%;
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        .item-row {
          flex-grow: 0;
          flex-shrink: 1;
          align-items: center;
          min-height: 24px;
          .source-img {
            height: 14px;
            width: 14px;
          }
          .fa-stack {
            display: inline-block;
            height: 14px;
            line-height: 13px;
            position: relative;
            vertical-align: middle;
            width: 14px;
            font-size: 14px;
            .fa-circle {
              left: 0;
              position: absolute;
              text-align: center;
              width: 100%;
              font-size: 14px;
            }
            .icon-social {
              left: 0;
              position: absolute;
              text-align: center;
              width: 100%;
              font-size: 8px;
              color: #fff;
              padding: 3px 0;
            }
            .icon-twitter {
              color: #252525;
            }
            .icon-instagram {
              color: #dd2a7b;
            }
            .icon-youtube {
              color: #e32212;
            }
            .icon-pantip {
              color: #7459c8;
            }
            .icon-website,
            .icon-tiktok {
              color: #252525;
            }
          }
          .icon-facebook {
            color: #1977f2;
          }
          .prog-desc {
            width: fit-content;
            height: fit-content;
            margin: 8px 0 0;
            .title {
              color: #868eae;
              font-size: 12px;
              font-weight: 500;
              line-height: 13px;
              text-transform: capitalize;
              padding: 0;
            }
            .percent {
              font-weight: 400;
              font-size: 10px;
            }
            .title-facebook {
              color: #1877f2;
            }
            .title-twitter {
              color: #1da1f2;
            }
            .title-instagram {
              color: #dd2a7b;
            }
            .title-youtube {
              color: #e32212;
            }
            .title-pantip {
              color: #7459c8;
            }
            .title-blockdit {
              color: #4a69ff;
            }
            .title-website,
            .title-tiktok {
              color: #252525;
            }
            .element {
              display: block;
            }
          }
          .prog-title {
            color: #868eae;
            font-size: 10px;
            line-height: 13px;
            text-transform: capitalize;
          }
          .prog-bar-middle {
            margin-top: 22px;
          }
          .prog-bar {
            .prog-bar-first {
              border-radius: 8px 0px 0px 8px;
            }
            .prog-bar-last {
              border-radius: 0px 8px 8px 0px;
            }
            .prog-bar-radius {
              border-radius: 8px;
            }
            .prog-segment {
              text-align: center;
              color: #fff;
              height: 12px;
              line-height: 12px;
              display: block;
            }
            .has-data {
              cursor: pointer;
            }
            .no-data {
              background-color: #aaa;
              width: 100%;
            }
            .facebook {
              background-color: #1877f2;
              border-left: 1px solid white;
            }
            .twitter {
              background-color: #1da1f2;
              border-left: 1px solid white;
            }
            .instagram {
              background-color: #dd2a7b;
              border-left: 1px solid white;
            }
            .youtube {
              background-color: #e32212;
              border-left: 1px solid white;
            }
            .pantip {
              background-color: #7459c8;
              border-left: 1px solid white;
            }
            .blockdit {
              background-color: #4a69ff;
              border-left: 1px solid white;
            }
            .website,
            .tiktok {
              background-color: #252525;
              border-left: 1px solid white;
            }
          }
        }
      }
    }
  }
  .social-row {
    row-gap: 12px;
    column-gap: 12px;
    border-bottom: none;
    margin-bottom: 0;
    padding: 0;
    justify-content: normal;
    .campaign-overall-social-col {
      flex: 1 1 auto;
    }
    .social-last {
      margin-right: 0 !important;
    }
    .social-block {
      display: block;
      height: 113px;
      padding: 12px;
      border: 1px solid #f1f2f6;
      border-radius: 4px;
      cursor: pointer;
      .social-col {
        height: fit-content;
        padding-right: 8px;
        line-height: 24px;
      }
      .overall-col {
        margin: auto 0;
        vertical-align: middle;
      }
      .source-img {
        height: 24px;
        width: 24px;
      }
      .fa-stack {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        position: relative;
        vertical-align: middle;
        width: 24px;
        font-size: 24px;
        .fa-circle {
          left: 0;
          position: absolute;
          text-align: center;
          width: 100%;
          font-size: 24px;
        }
        .icon-social {
          left: 0;
          position: absolute;
          text-align: center;
          width: 100%;
          font-size: 16px;
          color: #fff;
          padding: 4px 0;
        }
        .icon-twitter {
          color: #252525;
        }
        .icon-instagram {
          color: #dd2a7b;
        }
        .icon-youtube {
          color: #e32212;
        }
        .icon-pantip {
          color: #7459c8;
        }
        .icon-website {
          color: #1bc5bd;
        }
        .icon-tiktok {
          color: #252525;
        }
      }
      .icon-facebook {
        display: block;
        font-size: 24px;
        line-height: 24px;
        color: #1977f2;
      }
      .social-name {
        display: block;
        color: #5a5f7d;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
      }

      .social-count {
        margin: 0;
      }
      .social-value {
        display: block;
        color: #272b41;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
      }
      .social-footer {
        display: block;
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
        .social-percent {
          font-weight: 500;
          color: #5a5f7d;
          margin-right: 5px;
        }
        .social-mode {
          font-weight: 400;
          color: #9299b8;
          text-transform: capitalize;
        }
      }
    }
    .social-block-facebook {
      background-color: #f3f8fe;
    }
    .social-block-twitter {
      background-color: #f4fafe;
    }
    .social-block-instagram {
      background-color: #fdf4f8;
    }
    .social-block-youtube {
      background-color: #fef4f3;
    }
    .social-block-pantip {
      background-color: #f8f7fc;
    }
    .social-block-blockdit {
      background-color: #f6f8ff;
    }
    .social-block-website {
      background-color: #f4fcfc;
    }
    .social-block-tiktok {
      background-color: #f4f5f7;
    }
  }
}

@media screen and (min-width: 1581px) and (max-width: 1610px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 300px !important;
      }
    }
  }
}

@media screen and (min-width: 1537px) and (max-width: 1580px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 290px !important;
      }
    }
  }
}

@media screen and (min-width: 1503px) and (max-width: 1536px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 280px !important;
      }
    }
  }
}

@media screen and (min-width: 1463px) and (max-width: 1502px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 270px !important;
      }
    }
  }
}

@media screen and (min-width: 1389px) and (max-width: 1462px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 260px !important;
      }
    }
  }
}

@media screen and (min-width: 1373px) and (max-width: 1388px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 250px !important;
      }
    }
  }
}

@media screen and (min-width: 1343px) and (max-width: 1372px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 240px !important;
      }
    }
  }
}

@media screen and (min-width: 1322px) and (max-width: 1342px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 230px !important;
      }
    }
  }
}

@media screen and (min-width: 1306px) and (max-width: 1321px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 220px !important;
      }
    }
  }
}

@media screen and (min-width: 1283px) and (max-width: 1305px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 210px !important;
      }
    }
  }
}

@media screen and (min-width: 561px) and (max-width: 1305px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-row {
        .overall {
          .overall-icon {
            width: 46px !important;
            height: 46px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1239px) and (max-width: 1282px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 190px !important;
      }
      .overall-row {
        .overall-count {
          .overall-count {
            font-size: 24px !important;
            line-height: 29px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 561px) and (max-width: 1238px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 180px !important;
      }
      .overall-row {
        .overall-count {
          .overall-count {
            font-size: 20px !important;
            line-height: 29px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 561px) and (max-width: 1400px) {
  .campaign-overall {
    .social-row {
      overflow-x: auto;
      overflow-y: hidden;
      flex-wrap: nowrap;
    }
  }
}

@media screen and (min-width: 561px) and (max-width: 1320px) {
  .campaign-overall {
    .social-row {
      .social-block {
        min-width: 145px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .campaign-overall {
    .campaign-overall-row {
      .overall-header-row {
        min-width: 0 !important;
        width: 100% !important;
      }
      .overall-engagement {
        display: none;
      }
      .prog-row {
        margin-top: 16px !important;
      }
      .campaign-overall-col {
        width: 100% !important;
        margin-right: 0 !important;
      }
      .overall-row {
        .overall-count {
          .overall-count {
            font-size: 20px !important;
            line-height: 26px !important;
          }
        }
      }
    }
    .social-row {
      flex-direction: column;
      row-gap: 12px !important;
      .campaign-overall-social-col {
        width: 100%;
        flex: 0 0 auto;
        justify-content: space-between;
        .social-block {
          width: 100%;
          height: 67px;
          flex: 1 1 auto;
          display: flex;
          justify-content: space-between;
          .social-count {
            margin: auto 0;
            .social-value {
              display: flex;
              justify-content: flex-end;
            }
            .social-footer {
              display: flex;
              justify-content: flex-end;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
:deep(.icon-globe-website svg.feather.feather-globe) {
  width: 8px;
  height: 8px;
}
@media only screen and (max-width: 575px) {
  :deep(.ant-card-head-wrapper) {
    align-items: flex-start;
  }
  :deep(.ant-card-head-title) {
    text-transform: capitalize;
  }
}
</style>

import numeral from 'numeral';
import dayjs from 'dayjs';
import store from '@/store';
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const dateFormat = {
  hourly: 'DD MMM YYYY: HH:mm',
  daily: 'DD MMM YYYY',
  monthly: 'DD MMM YYYY',
  yearly: 'DD MMM YYYY',
};

const PTTColor = [
  '#F27649',
  '#844D9E',
  '#F29D35',
  '#FA339A',
  '#6F984C',
  '#F25252',
  '#F2CC66',
  '#14CB92',
  '#BD7A45',
  '#14A697',
  '#c53440',
  '#14ABD2',
  '#F28682',
  '#1A6DB4',
  '#625B6F',
];
const channelColor = [
  {
    channel: 'facebook',
    value: '#1877f2',
  },
  {
    channel: 'twitter',
    value: '#1da1f2',
  },
  {
    channel: 'instagram',
    value: '#dd2a7b',
  },
  {
    channel: 'youtube',
    value: '#e32212',
  },
  {
    channel: 'pantip',
    value: '#7459c8',
  },
  {
    channel: 'blockdit',
    value: '#4a69ff',
  },
  {
    channel: 'tiktok',
    value: '#252525',
  },
  {
    channel: 'website',
    value: '#1BC5BD',
  },
];

const replaceChar = [
  {
    original: /\u2002/g,
    change: ' ',
  },
];

const OtherColor = '#aaa';

// import defaultImage from '@/assets/images/icon/anonymous-profile.svg';
import defaultPlaceholder from '@/assets/images/placeholder_gray.png';

import defaultFacebookSourceImage from '@/assets/images/source-icon/svg/facebook.svg';
import defaultTwitterSourceImage from '@/assets/images/source-icon/svg/twitter.svg';
import defaultTikTokSourceImage from '@/assets/images/source-icon/svg/tiktok.svg';
import defaultInstagramSourceImage from '@/assets/images/source-icon/svg/instagram.svg';
import defaultPantipSourceImage from '@/assets/images/source-icon/svg/pantip.svg';
import defaultBlockditSourceImage from '@/assets/images/source-icon/svg/blockdit.svg';
import defaultWebsiteSourceImage from '@/assets/images/source-icon/svg/website.svg';
import defaultYoutubeSourceImage from '@/assets/images/source-icon/svg/youtube.svg';

function getQuery(inputStr, startStr) {
  const str = inputStr.replace(startStr, '');
  const seg = str.split('&');
  const resultObj = {};
  seg.forEach((s) => {
    const subSplit = s.split('=');
    if (subSplit[0] && subSplit[1]) {
      const { 0: key, 1: value } = subSplit;
      resultObj[key] = value;
    }
  });
  return resultObj;
}

function getColor(val, colorIndex) {
  const colorId = val.toLowerCase();
  const colorList = store.state.account.colors;
  let colorResult = '#333';
  if (colorId === 'positive') {
    return '#20C997';
  } else if (colorId === 'neutral') {
    return '#FEBC3C';
  } else if (colorId === 'negative') {
    return '#FF4D4F';
  } else if (colorId === 'instagram') {
    return '#EF426E';
  } else if (colorId === 'blockdit') {
    return '#4a69ff';
  } else if (colorId === 'tiktok') {
    return '#252525';
  } else if (colorId === 'website') {
    return '#1BC5BD';
  }

  if (colorList[colorId]) {
    colorResult = colorList[colorId];
  } else if (colorId === 'others') {
    colorResult = OtherColor;
  } else if (typeof colorIndex === 'number') {
    const targetIndex = colorIndex % PTTColor.length;
    colorResult = PTTColor[targetIndex];
  }
  return colorResult;
}

function getColorChannel(channel) {
  for (let c of channelColor) {
    if (c.channel === channel) {
      return c.value;
    }
  }
}

function getColorByCategory(categoryLevel, category, colorIndex) {
  const categoryName = category.toLowerCase();
  const categoryList = store.state.config.categoryData;
  let color;
  let result;
  if (
    categoryList &&
    categoryList[categoryLevel] &&
    categoryList[categoryLevel].categories
  ) {
    const categories = categoryList[categoryLevel].categories;
    result = categories.find((c) => c.visible && c.category === categoryName);
  }
  color = result && result.color ? result.color : null;

  if (!color) {
    color = getColor(categoryName, colorIndex);
  }
  return color;
}

function capitalize(value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function findMessageIndexById(listData, messageId) {
  let foundIndex = '';
  for (let i in listData) {
    if (listData[i].info.id === messageId) {
      foundIndex = i;
    }
  }
  return foundIndex;
}

function prepareUsernameWord(b) {
  let a = '';
  if (b.indexOf('@') !== null) {
    b = b.replaceAll(/^@*/g, '');
  }
  a = b.trim();
  if (a.indexOf('username:') !== 0) {
    return `username:${a}`;
  }
  return a;
}

function prepareHashTagWord(b) {
  const a = b.trim();
  if (a.indexOf('#') !== 0) {
    return `#${a}`;
  }
  return a;
}

export default {
  // Filters
  numeral: (value, format = '0,0', skipThousand = false, defaultVal = '') => {
    if (typeof value === 'string' || typeof value === 'number') {
      const v = numeral(value);
      const val = v.value();
      if (skipThousand && val < 1000) {
        return val;
      }
      // Filter out infinity or -infinity and NaN number after parsed
      if (val === Infinity || val === -Infinity || isNaN(val)) {
        return '---';
      }
      return v.format(format);
    }
    return defaultVal;
  },
  capitalize,
  getColorChannel,
  ellipsis: (text, size) => {
    return `${text.split(' ').slice(0, size).join(' ')}...`;
  },
  facebookAnonymous: (value, source) => {
    if (!value) return '';
    if (source === 'facebook') {
      return 'Anonymous';
    }
    return value;
  },
  truncate: (text, count = 250) => {
    if (!text) return '';
    if (text && text.length > count) {
      return `${text.substring(0, count)}...`;
    }
    return text;
  },
  timeFromNow: (str) => {
    return dayjs(str).fromNow();
  },
  formatDate: (str, format = '') => {
    return dayjs(str).format(format);
  },
  // Others
  getSearchQuery: () => getQuery(window.location.search, '?'),
  normalizeKey: (key) => {
    if (key.indexOf('_') > -1) {
      let splitted = key.split('_');
      if (splitted[0]) {
        splitted[0] =
          splitted[0].charAt(0).toUpperCase() + splitted[0].slice(1);
        return splitted[0];
      }
    }
    return key;
  },
  getDateByMode: (mode) => {
    let timeObj = {};
    switch (mode) {
      case 'default':
        timeObj = undefined;
        break;
      case 'today':
        timeObj = {
          sinceDate: dayjs().startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'yesterday':
        timeObj = {
          sinceDate: dayjs().subtract(1, 'days').startOf('day').valueOf(),
          untilDate: dayjs().subtract(1, 'days').endOf('day').valueOf(),
        };
        break;
      case 'last7days':
        timeObj = {
          sinceDate: dayjs().subtract(7, 'days').startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'last28days':
        timeObj = {
          sinceDate: dayjs().subtract(28, 'days').startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'lastMonth':
        timeObj = {
          sinceDate: dayjs().subtract(1, 'months').startOf('month').valueOf(),
          untilDate: dayjs().subtract(1, 'months').endOf('month').valueOf(),
        };
        break;
      case 'last37days':
        timeObj = {
          sinceDate: dayjs().subtract(37, 'days').startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'thisYear':
        timeObj = {
          sinceDate: dayjs().startOf('year').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'lastYear':
        timeObj = {
          sinceDate: dayjs().subtract(1, 'years').startOf('year').valueOf(),
          untilDate: dayjs().subtract(1, 'years').endOf('year').valueOf(),
        };
        break;
      case 'custom':
        timeObj = {
          sinceDate: dayjs().startOf('day').toDate(),
          untilDate: dayjs().endOf('day').toDate(),
        };
        break;
      case 'epoch':
        timeObj = {
          sinceDate: dayjs().startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      default:
        timeObj = undefined;
        break;
    }
    return timeObj;
  },
  getDefaultPlaceholder: () => {
    // console.log('DEFAULT', source);
    return defaultPlaceholder;
  },
  getDefaultImageProfile: (source) => {
    const defaultProfileImage = {
      facebook: defaultFacebookSourceImage,
      instagram: defaultInstagramSourceImage,
      twitter: defaultTwitterSourceImage,
      tiktok: defaultTikTokSourceImage,
      youtube: defaultYoutubeSourceImage,
      pantip: defaultPantipSourceImage,
      blockdit: defaultBlockditSourceImage,
      website: defaultWebsiteSourceImage,
    };
    let image = defaultProfileImage[source];
    if (!image) {
      image = defaultWebsiteSourceImage;
    }
    return image;
  },
  getSentimentText: (score) => {
    if (score >= 5) {
      return 'Positive';
    } else if (score >= 2.5) {
      return 'Slightly Positive';
    } else if (score >= 0) {
      return 'Neutral';
    } else if (score >= -2.5) {
      return 'Slightly Negative';
    } else if (score >= -5) {
      return 'Negative';
    }
  },
  getTimeOpt(inputTime, graphTime) {
    const result = {
      time: {
        sinceDate: null,
        untilDate: null,
      },
      title: '',
    };
    const selectedTime = dayjs(inputTime);
    let since, until;
    since = selectedTime.startOf('day');
    until = selectedTime.endOf('day');
    result.title = `on ${since.format(dateFormat.daily)}`;
    if (graphTime && graphTime.timeRange) {
      const fmt = dateFormat[graphTime.timeRange];
      if (graphTime.timeRange === 'hourly') {
        since = selectedTime;
        until = selectedTime.add(1, 'hours');
        result.title = `between ${since.format(fmt)} to ${until.format(fmt)}`;
      } else if (graphTime.timeRange === 'daily') {
        since = selectedTime.startOf('day');
        until = selectedTime.endOf('day');
        result.title = `on ${selectedTime.format(fmt)}`;
      } else if (graphTime.timeRange === 'monthly') {
        since = selectedTime.startOf('month');
        until = selectedTime.endOf('month');
        result.title = `between ${since.format(fmt)} to ${until.format(fmt)}`;
      } else if (graphTime.timeRange === 'yearly') {
        since = selectedTime.startOf('year');
        until = selectedTime.endOf('year');
        result.title = `between ${since.format(fmt)} to ${until.format(fmt)}`;
      }
    }
    result.time.sinceDate = since.valueOf();
    result.time.untilDate = until.valueOf();
    return result;
  },
  saveLocalMessage(result, listData) {
    const {
      messageId,
      category,
      sentiment = null,
      editing = null,
      alerted = null,
      reviewed = null,
      read = null,
      removed = null,
    } = result;
    const foundIndex = findMessageIndexById(listData, messageId);
    if (foundIndex !== '') {
      if (removed !== null) {
        // reject is remove from list
        listData.splice(foundIndex, 1);
      } else {
        let obj = JSON.parse(JSON.stringify(listData[foundIndex]));
        if (category) {
          for (let c of category) {
            if (c.level == 0) {
              obj.analytic.categories = [...c.category];
            } else {
              const t = `sub_categories_level_${c.level}`;
              obj.analytic[t] = [...c.category];
            }
          }
        } else if (sentiment !== null) {
          obj.analytic.sentiment_score = sentiment;
        } else if (editing !== null) {
          obj.editing = editing;
        } else if (alerted !== null) {
          obj.analytic.alerted = alerted;
        } else if (reviewed !== null) {
          obj.analytic.reviewed = reviewed;
        } else if (read !== null) {
          obj.analytic.read = read;
        }
        Object.assign(listData[foundIndex], obj);
      }
    }
  },
  prepareCategoryChartECV(
    graphData,
    visibleCategory,
    drilldownFn,
    selectedCategoryLevel = 0,
    catList,
    granularity
  ) {
    const newEngagementSeries = [];
    const newCountSeries = [];
    const newViewSeries = [];
    // console.log(graphData, visibleCategory);

    if (graphData) {
      let count = 0;
      if (catList) {
        for (let category of catList) {
          const sentimentData = graphData[category];
          if (visibleCategory.has(category)) {
            let name = capitalize(category);
            // const color = getColor(category.toLowerCase(), count);
            const color = getColorByCategory(
              selectedCategoryLevel,
              category,
              count
            );
            const pl = {
              title: `${name} category`,
              criteria: {},
            };
            const engagementDataList = [];
            const countDataList = [];
            const viewDataList = [];

            if (sentimentData && sentimentData.data) {
              const data = sentimentData.data;
              data.forEach((entry) => {
                let x = entry.x;
                if (entry.y != null) {
                  let yEngagement = entry.y.engagement || 0;
                  engagementDataList.push([x, yEngagement]);
                  let yCount = entry.y.count || 0;
                  countDataList.push([x, yCount]);
                  let yView = entry.y.view || 0;
                  viewDataList.push([x, yView]);
                }
              });
            } else {
              // Try using first data as x axis
              const keys = Object.keys(graphData);
              if (keys && keys[0]) {
                const d = graphData[keys[0]];
                if (d && d.data) {
                  d.data.forEach((entry) => {
                    let x = entry.x;
                    engagementDataList.push([x, 0]);
                    countDataList.push([x, 0]);
                    viewDataList.push([x, 0]);
                  });
                }
              }
            }
            if (selectedCategoryLevel === 0) {
              pl.criteria.category = [category];
            } else {
              pl.criteria.subCategory = [
                {
                  level: selectedCategoryLevel,
                  category: [category],
                },
              ];
            }

            if (name) {
              count++;
              newEngagementSeries.push({
                name: name,
                color: color,
                data: engagementDataList,
                point: {
                  events: {
                    click: (e) => drilldownFn(e.point.options, pl),
                  },
                },
                custom: {
                  granularity,
                },
              });
              newCountSeries.push({
                name: name,
                color: color,
                data: countDataList,
                point: {
                  events: {
                    click: (e) => drilldownFn(e.point.options, pl),
                  },
                },
                custom: {
                  granularity,
                },
              });
              newViewSeries.push({
                name: name,
                color: color,
                data: viewDataList,
                point: {
                  events: {
                    click: (e) => drilldownFn(e.point.options, pl),
                  },
                },
                custom: {
                  granularity,
                },
              });
            }
          }
        }
      }
    }

    return {
      engagement: newEngagementSeries,
      mentioned: newCountSeries,
      view: newViewSeries,
    };
  },
  convertRawCategory(catObj, visibleCategoryList) {
    const levelData = [];
    let category = [];
    const subCategory = [];
    console.log('CV', visibleCategoryList);

    Object.keys(catObj).forEach((catName) => {
      for (let c of catObj[catName]) {
        const splitted = c.split('::');
        const levelNo = parseInt(splitted[0], 10);
        if (!levelData[levelNo]) levelData[levelNo] = [];
        levelData[levelNo].push(splitted[1]);
        // TODO is this also dangerous code?
      }
    });
    for (let i in levelData) {
      const l = levelData[i];
      let c;
      if (l.length > 0) {
        c = l;
      } else {
        c = visibleCategoryList[i];
      }
      if (i == 0) {
        category = c;
      } else {
        subCategory.push({
          level: i,
          category: c,
        });
      }
    }
    return {
      category,
      subCategory,
    };
  },
  convertRawCampaign(catObj, visibleCategoryList, level) {
    let category = [];
    const subCategory = [];
    const rawCategory = [];

    Object.keys(catObj).forEach((catName) => {
      for (let c of catObj[catName]) {
        const splitted = c.split('::');
        const levelNo = parseInt(splitted[0], 10);
        if (levelNo === level) {
          rawCategory.push(splitted[1]);
          category.push(c);
        }
      }
    });
    for (let i in rawCategory) {
      if (parseInt(i) === level) {
        const l = rawCategory[i];
        let c;
        if (l.length > 0) {
          c = l;
        } else {
          c = visibleCategoryList[i];
        }
        if (i == 0) {
          category = c;
        } else {
          subCategory.push({
            level: i,
            category: c,
          });
        }
      }
    }
    return {
      category,
      subCategory,
      rawCategory,
    };
  },
  getColor,
  getColorByCategory,
  hexToRGB(hex, opacity = 1) {
    let r = 0,
      g = 0,
      b = 0;

    // 3 digits
    if (hex.length == 4) {
      r = '0x' + hex[1] + hex[1];
      g = '0x' + hex[2] + hex[2];
      b = '0x' + hex[3] + hex[3];

      // 6 digits
    } else if (hex.length == 7) {
      r = '0x' + hex[1] + hex[2];
      g = '0x' + hex[3] + hex[4];
      b = '0x' + hex[5] + hex[6];
    }

    return 'rgb(' + +r + ',' + +g + ',' + +b + ',' + +opacity + ')';
  },
  getTableSortFn: function (type, key) {
    return function (a, b) {
      if (type === 'string') {
        return a[key].localeCompare(b[key]);
      } else if (type === 'number') {
        return a[key] - b[key];
      } else if (type === 'date-x') {
        return a[key] - b[key];
      } else if (type === 'date-iso') {
        return dayjs(a[key]).diff(dayjs(b[key]));
      }
    };
  },
  prepareUsernameWord,
  prepareHashTagWord,
  prepareKeywordList(obj) {
    const { mode, list } = obj;
    // console.log(mode, list[0]);

    if (mode === 'word')
      return list.map((a) => {
        return a.trim();
      });
    if (mode === 'username') return list.map(prepareUsernameWord);
    if (mode === 'hashtag') {
      return list.map(prepareHashTagWord);
    }
  },

  sortOtherItemAtFirst(arr, oldIndex) {
    if (arr && arr.length > 0 && oldIndex > -1) {
      arr.splice(0, 0, arr.splice(oldIndex, 1)[0]);
    }
    return arr;
  },

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  },

  replaceExpectChar(word) {
    let wordNoExceptChar;
    for (const replaceOrder of replaceChar) {
      wordNoExceptChar = word.replace(
        replaceOrder.original,
        replaceOrder.change
      );
    }
    return wordNoExceptChar;
  },
};

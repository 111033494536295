<template>
  <a-layout class="app-wrapper">
    <div v-if="!filterCollapsed" id="app-overlay" @click="closeFilter"></div>
    <div
      v-if="(isMobileScreen || isSmallScreen) && !sideNavCollapsed"
      id="sidebar-overlay"
      @click="toggleCollapsed(true)"
    ></div>
    <a-layout-sider
      id="main-sidebar"
      v-model:collapsed="sideNavCollapsed"
      :class="{ 'is-fixed': isMobileScreen || isSmallScreen }"
      :trigger="null"
      :width="280"
      breakpoint="lg"
      :collapsed-width="sidebarCollapsedWidth"
      collapsible
      @collapse="onCollapse"
    >
      <div class="navbar-brand" :class="{ collapsed: sideNavCollapsed }">
        <a-button class="btn-transparent" type="ghost" @click="toggleCollapsed(true)">
          <img :src="require(`../../assets/images/icon/${sideNavCollapsed ? 'right.svg' : 'left.svg'}`)" alt="menu" />
        </a-button>
        <router-link class="logo top-menu" to="/">
          <img class="dom_logo" :src="require('../../assets/images/logo/logo_dom-white.svg')" />
        </router-link>
      </div>
      <perfect-scrollbar
        ref="scrollBar"
        class="sidebar-ps"
        :options="{
          wheelSpeed: 1,
          swipeEasing: true,
          suppressScrollX: true,
        }"
      >
        <div class="sidebar-wrapper">
          <div class="side-menu-wrapper">
            <div class="account-wrapper" :class="{ collapsed: sideNavCollapsed }">
              <a-card class="account-card">
                <div class="acccount-card-body">
                  <Avatar class="avatar" :src="accountProfileImage" :username="brand" :size="40"></Avatar>
                  <div v-if="!sideNavCollapsed" class="acc-text">
                    {{ brand }}
                  </div>
                </div>
              </a-card>
            </div>

            <div class="side-menu-items">
              <p v-if="role !== 'accountmanager'" class="sidebar-nav-title menu-title">Overall</p>
              <a-menu
                v-if="role !== 'accountmanager'"
                v-model:openKeys="openKeys"
                v-model:selectedKeys="selectedKeys"
                :mode="sidebarMode"
                :theme="'dark'"
              >
                <a-sub-menu key="dashboard">
                  <template #title><FeatherIcon type="home" /><span>Dashboard</span></template>
                  <router-link to="/">
                    <a-menu-item key="home" @click="toggleCollapsed(false)">
                      <!-- <FeatherIcon type="home" /> -->
                      <span class="sub-menu-dashboard"> Overview </span>
                    </a-menu-item>
                  </router-link>
                  <router-link to="/comparison">
                    <a-menu-item key="comparison" @click="toggleCollapsed(false)">
                      <!-- <FeatherIcon type="bar-chart-2" /> -->
                      <span class="sub-menu-dashboard"> Comparison </span>
                    </a-menu-item>
                  </router-link>
                  <!-- <router-link to="/own-media">
                    <a-menu-item key="own-media" @click="toggleCollapsed(false)">
                      <FeatherIcon type="grid" />
                      <span class="sub-menu-dashboard"> Own Media </span>
                    </a-menu-item>
                  </router-link> -->
                  <!--                  <router-link to="/influencer">-->
                  <!--                    <a-menu-item key="influencer" @click="toggleCollapsed(false)">-->
                  <!--                      &lt;!&ndash; <FeatherIcon type="users" /> &ndash;&gt;-->
                  <!--                      <span class="sub-menu-dashboard"> Influencer Analysis </span>-->
                  <!--                    </a-menu-item>-->
                  <!--                  </router-link>-->
                  <router-link to="/influencer">
                    <a-menu-item key="influencer" @click="toggleCollapsed(false)">
                      <!-- <FeatherIcon type="users" /> -->
                      <span class="sub-menu-dashboard"> Influencer Analysis </span>
                    </a-menu-item>
                  </router-link>
                </a-sub-menu>
                <router-link to="/live-feed">
                  <a-menu-item key="live-feed" class="menu-item-dashboard" @click="toggleCollapsed(false)">
                    <FeatherIcon type="message-square" />
                    <span> Live Feed </span>
                  </a-menu-item>
                </router-link>
                <router-link to="/explore">
                  <a-menu-item key="explore" class="menu-item-dashboard" @click="toggleCollapsed(false)">
                    <FeatherIcon type="search" />
                    <span> Explore </span>
                  </a-menu-item>
                </router-link>
                <router-link v-if="role !== 'feedonly'" to="/location">
                  <a-menu-item key="location" class="menu-item-dashboard" @click="toggleCollapsed(false)">
                    <FeatherIcon type="map-pin" />
                    <span> Message Location </span>
                  </a-menu-item>
                </router-link>
              </a-menu>

              <div v-if="role !== 'accountmanager'" class="sidebar-nav-title menu-title menu-campaign mr-menu">
                <p class="campaign-text">Campaign</p>
                <div v-if="isUserCanAddCampaign">
                  <router-link to="/campaign/add">
                    <FeatherIcon type="plus-square" class="icon-add-campaign" />
                  </router-link>
                </div>
              </div>
              <a-menu
                v-if="role !== 'accountmanager'"
                v-model:openKeys="openKeys"
                v-model:selectedKeys="selectedKeys"
                :mode="sidebarMode"
                :theme="'dark'"
              >
                <a-sub-menu v-if="role !== 'feedonly'" key="campaign">
                  <template #title><FeatherIcon type="file-text" /><span>Campaign</span></template>
                  <router-link to="/campaign/comparison">
                    <a-menu-item key="campaign-comparison" class="menu-item-comparison" @click="toggleCollapsed(false)">
                      Comparison
                    </a-menu-item>
                  </router-link>
                  <router-link
                    v-for="(campaign, index) in campaignMenuData.categoryList"
                    :key="index"
                    :to="`/campaign/${encodeURIComponent(campaign.categoryName)}`"
                  >
                    <a-menu-item
                      :key="index"
                      class="campaign"
                      :class="{
                        'campaign-nonSelected': campaignName !== campaign.categoryName,
                      }"
                      @click="toggleCollapsed(false)"
                    >
                      <FeatherIcon
                        :class="campaign.visible === true ? 'campaign-active' : 'campaign-inactive'"
                        type="circle"
                        :size="10"
                      />
                      {{ campaign.categoryName }}
                    </a-menu-item>
                  </router-link>
                </a-sub-menu>
              </a-menu>

              <p v-if="role !== 'accountmanager'" class="sidebar-nav-title menu-title mr-menu">Export</p>
              <a-menu
                v-if="role !== 'accountmanager'"
                v-model:openKeys="openKeys"
                v-model:selectedKeys="selectedKeys"
                :mode="sidebarMode"
                :theme="'dark'"
              >
                <router-link v-if="role !== 'feedonly'" to="/export">
                  <a-menu-item key="export" @click="toggleCollapsed(false)">
                    <FeatherIcon type="download" />
                    <span> Export </span>
                  </a-menu-item>
                </router-link>
              </a-menu>

              <template v-if="role === 'admin' || role === 'domadmin' || role === 'accountmanager'">
                <p class="sidebar-nav-title menu-title mr-menu">SETTINGS</p>
                <a-menu
                  v-model:openKeys="openKeys"
                  v-model:selectedKeys="selectedKeys"
                  :mode="sidebarMode"
                  :theme="'dark'"
                >
                  <a-sub-menu v-if="role !== 'accountmanager'" key="data-setting">
                    <template #title><FeatherIcon type="settings" /><span>DOM Setting</span></template>
                    <router-link to="/settings">
                      <a-menu-item key="setting-dashboard" @click="toggleCollapsed(false)"> Dashboard </a-menu-item>
                    </router-link>
                    <router-link to="/keyword-setting">
                      <a-menu-item key="keyword-setting" @click="toggleCollapsed(false)">
                        Keyword & Exclude
                      </a-menu-item>
                    </router-link>
                    <router-link to="/category-setting">
                      <a-menu-item key="category-setting" @click="toggleCollapsed(false)"> Category </a-menu-item>
                    </router-link>
                    <router-link to="/source-setting">
                      <a-menu-item key="source-setting" @click="toggleCollapsed(false)"> Source </a-menu-item>
                    </router-link>
                  </a-sub-menu>
                  <router-link to="/user-manager">
                    <a-menu-item key="user-manager" @click="toggleCollapsed(false)">
                      <FeatherIcon type="user" />
                      <span> User & Role </span>
                    </a-menu-item>
                  </router-link>
                </a-menu>
              </template>
            </div>
            <div class="usage-wrapper" :class="{ collapsed: sideNavCollapsed }">
              <a-card class="usage-card">
                <div class="icon-row">
                  <template v-if="!sideNavCollapsed">
                    <FeatherIcon class="icon" type="pie-chart" size="16" />
                    <span> Data Usage</span>
                  </template>
                  <a-tooltip v-else placement="right" overlay-class-name="usage-tooltip">
                    <template #title>
                      <div>
                        This month usage: <br />
                        {{ usageVal }} / {{ quotaVal }}
                      </div>
                    </template>
                    <FeatherIcon class="icon" type="pie-chart" size="16" />
                  </a-tooltip>
                </div>
                <template v-if="!sideNavCollapsed">
                  <div class="row2">This month messages :</div>
                  <div class="row3">
                    <span class="text-bold"> {{ usageVal }} </span>
                    <span class="text-light"> used </span>
                  </div>
                  <div class="row4">
                    <span class="text-light"> Usage package: {{ quotaVal }} </span>
                  </div>
                  <div v-if="role === 'admin' || role === 'superuser'" class="row5 text-bold">
                    <span>History</span>
                    <FeatherIcon class="icon" type="arrow-right" size="16" />
                  </div>
                </template>
              </a-card>
            </div>
          </div>
          <div class="side-menu-footer" :class="{ collapsed: sideNavCollapsed }">
            <template v-if="sideNavCollapsed">
              <a-tooltip placement="right" overlay-class-name="usage-tooltip">
                <template #title>
                  <div>2023 © InsightEra Co., Ltd. <a-tag color="#3371ff" class="version-tag">V 3.2.0</a-tag></div>
                </template>
                <FeatherIcon class="icon footer-icon" type="info" size="16" />
              </a-tooltip>
              <a-tooltip placement="right" overlay-class-name="usage-tooltip">
                <template #title>
                  <div class="link-row">
                    <router-link to="/terms-and-service">Terms&Conditions</router-link> <br />
                    <router-link to="/privacy-policy">Privacy Policy</router-link>
                  </div>
                </template>
                <FeatherIcon class="icon footer-icon" type="more-horizontal" size="16" />
              </a-tooltip>
            </template>
            <div v-else class="normal-footer">
              <div class="version-row">
                2023 © InsightEra Co., Ltd. <a-tag color="#3371ff" class="version-tag">V 3.2.0</a-tag>
              </div>
              <div class="link-row">
                <router-link to="/terms-and-service" style="color: #adb4d2">Terms&Conditions</router-link> ∙
                <router-link to="/privacy-policy" style="color: #adb4d2">Privacy Policy</router-link>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </a-layout-sider>
    <a-layout>
      <a-layout-header id="header-bar" :class="{ mobile: isMobileScreen, small: isSmallScreen }">
        <a-row class="header-row">
          <a-col v-if="sideNavCollapsed" :xs="3" :sm="0">
            <!-- Mobile toggle -->
            <a-button class="btn-transparent mobile-toggle" type="ghost" @click="toggleCollapsed(false)">
              <img
                :src="require(`../../assets/images/icon/${sideNavCollapsed ? 'right.svg' : 'left.svg'}`)"
                alt="menu"
              />
            </a-button>
          </a-col>
          <a-col :xs="0" :sm="12">
            <AdvanceSearch v-if="!hideSearch" />
          </a-col>
          <a-col :xs="18" :sm="0" class="mobile-search-col">
            <a-button class="mobile-search-toggle" type="ghost" @click="mobileSearchOpen = !mobileSearchOpen">
              <FeatherIcon v-if="mobileSearchOpen" type="x" size="16" color="#fff" />
              <FeatherIcon v-else type="search" size="16" color="#fff" />
            </a-button>
          </a-col>
          <a-col :xs="3" :sm="12" class="user-col">
            <a-dropdown>
              <!-- <a class="ant-dropdown-link" @click.stop=""> -- {{ username }} </a> -->
              <Avatar class="avatar cursor-pointer" :username="username" :size="40"></Avatar>
              <template #overlay>
                <a-menu class="user-menu">
                  <a-menu-item class="account-item-row">
                    <a-card class="account-card light">
                      <div class="acccount-card-body">
                        <Avatar class="avatar" :username="username" :size="40"></Avatar>
                        <div v-if="!collapsed">
                          <div class="acc-text">
                            {{ username }}
                          </div>
                          <div class="role-text">{{ emailText }}</div>
                        </div>
                      </div>
                    </a-card>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="$router.push({ name: 'profile', params: { tab: 'profile' } })"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="user" />
                      Edit Profile
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="$router.push({ name: 'profile', params: { tab: 'password' } })"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="lock" />
                      Change Password
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="$router.push({ name: 'profile', params: { tab: 'notification' } })"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="lock" />
                      Notification Setting
                    </a>
                  </a-menu-item>
                  <a-menu-item class="user-menu-item-wrapper logout-btn" @click="signOut">
                    <a class="user-menu-item">
                      <FeatherIcon type="log-out" />
                      Sign out
                    </a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-col>
        </a-row>
      </a-layout-header>
      <div v-if="!hideSearch && isMobileScreen && mobileSearchOpen" id="mobile-search-row">
        <AdvanceSearch v-if="!hideSearch" />
      </div>
      <a-layout-content
        id="main-content"
        :class="{ 'small-screen': isSmallScreen }"
        @scroll.capture.passive="onPageScroll"
      >
        <slot></slot>
        <div class="to-top-button">
          <a-button v-show="enableScrollTop && hasScrollBottom" class="circular" type="primary" @click="scrollToTop">
            <FeatherIcon type="arrow-up" />
          </a-button>
        </div>
      </a-layout-content>
    </a-layout>
    <FilterSidebar />
  </a-layout>
</template>
<script>
import { ref, reactive, computed, watch, watchEffect, toRefs, onMounted, onUnmounted, provide } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';

import helper from '@/services/helper';
import Avatar from '@/components/Avatar.vue';
import FilterSidebar from '@/components/Sidebar/FilterSidebar.vue';
import AdvanceSearch from './AdvanceSearch.vue';

export default {
  components: {
    Avatar,
    FilterSidebar,
    AdvanceSearch,
  },
  setup() {
    const store = useStore();
    const route = computed(() => useRoute());

    const filterShow = ref('');
    provide('showMode', filterShow);

    // Filter Sidebar
    const filterCollapsed = computed(() => store.state.filter.filterCollapsed);
    const campaignMenuData = computed(() => store.state.account.campaignMenuData);
    const campaignName = computed(() =>
      decodeURIComponent(route.value.params.id) ? decodeURIComponent(route.value.params.id).toLowerCase() : ''
    );
    const closeFilter = () => {
      store.dispatch('filter/toggleFilter', true);
    };

    // Side nav
    const forceHideLargeScreen = ref(false);
    const isMobileScreen = ref(false);
    const isSmallScreen = ref(false);
    const sideNavCollapsed = ref(false);
    const sidebarMode = ref('inline');
    const state = reactive({
      selectedKeys: ['home'],
      openKeys: ['dashboard'],
      preOpenKeys: ['dashboard'],
    });
    const onCollapse = (result) => {
      sideNavCollapsed.value = result;
      if (result) {
        sidebarMode.value = 'vertical';
      } else {
        sidebarMode.value = 'inline';
      }
      // console.log(sideNavCollapsed.value, sidebarMode.value);
    };
    // Width track // TODO make this global
    const windowWidth = ref(window.innerWidth);
    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
    };
    const sidebarCollapsedWidth = ref(76);
    const checkWidthVal = () => {
      if (windowWidth.value < 576) {
        isMobileScreen.value = true;
        isSmallScreen.value = false;
        sidebarCollapsedWidth.value = 0;
      } else if (windowWidth.value < 992) {
        sidebarCollapsedWidth.value = 76;
        isMobileScreen.value = false;
        isSmallScreen.value = true;
      } else {
        sidebarCollapsedWidth.value = 76;
        isMobileScreen.value = false;
        isSmallScreen.value = false;
      }
    };

    watch(
      () => windowWidth.value,
      () => checkWidthVal()
    );
    // Sidebar toggle
    const toggleCollapsed = (forceToggle = false) => {
      // console.log('M/S/F', isMobileScreen.value, isSmallScreen.value, forceToggle);
      if (isMobileScreen.value || (isSmallScreen.value && forceToggle)) {
        // mobile = no sidebar at all
        // force toggle is top toggle
        sideNavCollapsed.value = !sideNavCollapsed.value;
        // if toggle true set sidebar mode to inline ja
        if (sideNavCollapsed.value) {
          sidebarMode.value = 'vertical';
        } else {
          sidebarMode.value = 'inline';
        }
      } else if (isSmallScreen.value && !forceToggle) {
        // small screen normal toggle should not toggle type
        // sideNavCollapsed.value = !sideNavCollapsed.value;
      } else if (forceToggle) {
        if (forceHideLargeScreen.value) {
          // if true means its already collapse in large screen
          // then toggle it means to open it
          forceHideLargeScreen.value = false;
          sideNavCollapsed.value = false;
          sidebarMode.value = 'inline';
        } else {
          forceHideLargeScreen.value = true;
          sideNavCollapsed.value = true;
          sidebarMode.value = 'vertical';
        }
      }
    };
    watch(
      () => route.value.name,
      async () => {
        // console.log('RCH', route.value.name);
        if ((isMobileScreen.value || isSmallScreen.value) && sideNavCollapsed) {
          // Bug fix ???
          state.openKeys = [];
        } else {
          const { name } = route.value;
          if (name === 'home' || name === 'comparison' || name === 'influencer' || name === 'own-media') {
            state.openKeys = ['dashboard'];
          }
          if (name === 'campaign' || name === 'campaign comparison') {
            state.openKeys = ['campaign'];
          }
          if (
            name === 'setting-dashboard' ||
            name === 'keyword-setting' ||
            name === 'category-setting' ||
            name === 'source-setting'
          ) {
            state.openKeys = ['data-setting'];
          }
          state.selectedKeys = [route.value.name];
        }
        // console.log(route.value.meta);

        if (route.value.meta.enableScrollTop) {
          enableScrollTop.value = true;
        } else {
          enableScrollTop.value = false;
        }

        if (route.value.meta.hideSearch) {
          hideSearch.value = true;
        } else {
          hideSearch.value = false;
        }
      }
    );
    const scrollBar = ref(null);
    const setScroll = async () => {
      const { name } = route.value;
      if (
        name === 'setting-dashboard' ||
        name === 'keyword-setting' ||
        name === 'category-setting' ||
        name === 'source-setting'
      ) {
        scrollBar.value.$el.scrollTop = 0;
        scrollBar.value.$el.behavior = 'smooth';
      }
    };
    // watchEffect(() => {
    //   console.log('OPENKEYS?', router.value.matched);

    //   if (router.value.matched.length) {
    //     // TODO fix this !!
    //     state.selectedKeys = [router.value.matched[0].name];
    //     state.openKeys = [router.value.matched[0].name];
    //     state.preOpenKeys = [router.value.matched[0].name];
    //     // if (router.value.matched.length > 2) {
    //     //   state.selectedKeys = [router.value.matched[2].name];
    //     //   state.openKeys = [router.value.matched[1].name];
    //     //   state.preOpenKeys = [router.value.matched[1].name];
    //     // } else if (router.value.matched.length > 3) {
    //     //   state.selectedKeys = [router.value.matched[3].name];
    //     //   state.openKeys = [router.value.matched[1].name];
    //     //   state.preOpenKeys = [router.value.matched[1].name];
    //     // } else {
    //     //   state.selectedKeys = [router.value.matched[1].name];
    //     //   state.openKeys = [router.value.matched[1].name];
    //     //   state.preOpenKeys = [router.value.matched[1].name];
    //     // }
    //   }
    // });

    watchEffect(async () => {
      // Sync value to store
      const navLayoutData = {
        sideNavCollapsed: sideNavCollapsed.value,
        isSmallScreen: isSmallScreen.value,
        isMobileScreen: isMobileScreen.value,
      };
      await setScroll();
      // console.log('Effect trigger - sync nav to store', navLayoutData);
      store.dispatch('setNavLayoutData', navLayoutData);
    });

    // watch(
    //   () => state.openKeys,
    //   (val, oldVal) => {
    //     state.preOpenKeys = oldVal;
    //   }
    // );
    // Side nav val
    const brand = computed(() => store.state.account.brand);
    const accountProfileImage = computed(() => store.state.account.userAccount.accountImageURL);
    const username = computed(() => store.getters['account/userName']);
    const role = computed(() => store.getters['account/userRole']);
    const isUserCanAddCampaign = computed(() => {
      return !['accountmanager', 'user', 'feedonly'].includes(role.value);
    });
    const emailText = computed(() => store.getters['account/userEmail']);
    const usageVal = computed(() => {
      return helper.numeral(store.state.account.totalUsage);
    });
    const quotaVal = computed(() => {
      const { quota } = store.state.account.summary;
      if (quota && quota > 0) {
        return helper.numeral(quota);
      }
      return 'unlimited';
    });

    // Header search
    const hideSearch = ref(false);
    const searchInput = ref('');
    const quickSearch = _.debounce(() => {
      // console.log('search ', searchInput.value);
      store.dispatch('filter/updateTempCriteria', {
        keywordPhrase: searchInput.value,
      });
      store.dispatch('filter/applyFilter');
    }, 500);
    const filterSearchInput = computed(() => store.getters['filter/searchInput']);
    watch(
      () => filterSearchInput.value,
      () => {
        searchInput.value = filterSearchInput.value;
      }
    );
    const mobileSearchOpen = ref(false);

    // Signout
    const signOut = () => {
      store.dispatch('filter/clearFilter');
      localStorage.removeItem('category-name');
      window.location = '/login?out=1';
    };

    // Scroll to top
    let scrollElement;
    const enableScrollTop = ref(false);
    const hasScrollBottom = ref(false);
    const onPageScroll = _.debounce(() => {
      scrollElement = document.querySelector('#main-content .page-content');
      if (scrollElement && scrollElement.scrollTop > 200) {
        hasScrollBottom.value = true;
      } else {
        hasScrollBottom.value = false;
      }
    }, 250);

    const scrollToTop = () => {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    };

    onMounted(async () => {
      window.addEventListener('resize', onWidthChange);
      checkWidthVal();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', onWidthChange);
    });

    return {
      sidebarCollapsedWidth,
      isMobileScreen,
      isSmallScreen,
      sideNavCollapsed,
      toggleCollapsed,
      onCollapse,
      brand,
      username,
      role,
      emailText,
      usageVal,
      quotaVal,
      sidebarMode,
      filterCollapsed,
      closeFilter,
      ...toRefs(state),
      quickSearch,
      searchInput,
      mobileSearchOpen,
      hideSearch,
      signOut,
      scrollToTop,
      hasScrollBottom,
      enableScrollTop,
      onPageScroll,
      campaignMenuData,
      route,
      campaignName,
      scrollBar,
      accountProfileImage,
      isUserCanAddCampaign,
    };
  },
};
</script>

<style lang="scss">
@import '../../config/theme/colors.json';
.mr-menu {
  margin-top: 18px;
}
#main-sidebar .ant-menu-inline.ant-menu-sub .menu-item-comparison.ant-menu-item .ant-menu-title-content {
  padding-left: 50px !important;
}
#main-sidebar .ant-menu-inline.ant-menu-sub .menu-item-dashboard.ant-menu-item .ant-menu-title-content {
  padding-left: 15px !important;
}
#main-sidebar .ant-menu-inline.ant-menu-sub .campaign.ant-menu-item .ant-menu-title-content {
  padding-left: unset !important;
}
.sub-menu-dashboard {
  padding-left: unset !important;
}
.campaign-text {
  margin: unset;
}
.icon-add-campaign {
  color: #717885;
  margin-left: -24px;
}
.menu-campaign {
  justify-content: space-between;
  align-items: center;
}
.ps.sidebar-ps {
  height: calc(100vh - 66px);
}

.ant-menu-item[data-menu-id='add-campaign'] {
  border: 1px solid #f4f5f7;

  .ant-menu-title-content {
    border-right: 1px solid #f4f5f7;
  }
}

#main-sidebar .ant-menu-inline.ant-menu-sub .ant-menu-item[data-menu-id='add-campaign'] .ant-menu-title-content {
  padding-left: 80px !important;
}

.menu-add-new {
  border-radius: 4px;
}

#header-bar {
  background: #fff;
  height: 64px;
  padding: 0px 28px;
  width: 100%;
  box-shadow: 0 2px 30px #9299b810;
  .search-input {
    height: 48px;
  }
  &.small {
    padding-left: 100px;
  }
  &.mobile {
    background: #182133;
    padding: 0 10px 0 10px;
  }
  .mobile-toggle {
    height: 64px;
  }
  .mobile-search-col {
    text-align: right;
    .mobile-search-toggle {
      height: 64px;
    }
  }
  .header-row {
    width: 100%;
    height: 64px;
    line-height: 54px;
    .user-col {
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .certain-category-search-wrapper {
    width: 100%;
    height: 100%;
    .ant-row {
      height: 100%;
    }
  }
  .ant-input {
    border: none;
  }
  .ant-input:focus {
    border: none;
    outline: 0;
    box-shadow: none;
  }
  .certain-category-icon {
    font-size: 16px;
    position: relative;
    bottom: -2px;
    color: $gray-color;
    @media only screen and (max-width: 767px) {
      bottom: 0;
    }
    svg {
      margin-top: 4px;
      @media only screen and (max-width: 767px) {
        width: 12px;
      }
    }
  }
}
#mobile-search-row {
  width: 100%;
  position: absolute;
  top: 64px;
  left: 0;
  height: 60px;
  z-index: 10;
  background-color: #fff;
  align-items: center;
  display: flex;
  padding: 0 10 px;
  .search-input {
    border: none !important;
    box-shadow: none !important;
  }
}
#main-sidebar {
  color: #fff;
  overflow: hidden;
  height: 100vh;
  max-height: -webkit-fill-available;
  left: 0;
  z-index: 2;
  &.ant-layout-sider {
    box-shadow: 0 0 30px #9299b810;
    overflow: hidden;
    @media (max-width: 991px) {
      box-shadow: 0 0 10px #00000020;
    }
    &.ant-layout-sider-dark {
      background: $dark-color;
      .ant-layout-sider-children {
        .ant-menu {
          .ant-menu-submenu-inline {
            > .ant-menu-submenu-title {
              // padding: 0 15px !important;
              padding: 0 !important;
            }
          }
          .ant-menu-item {
            // padding: 0 15px !important;
            padding: 0 !important;
            height: 42px;
            margin: 0;
          }
        }
      }
    }

    .ant-layout-sider-children {
      padding-bottom: 15px;
      width: 280px;

      > .sidebar-nav-title {
        margin-top: 8px;
      }

      .ant-menu {
        /* overflow-x: hidden; */
        border-right: 0 none;
        box-shadow: none !important;
        .ant-menu-submenu,
        .ant-menu-item {
          i {
            line-height: normal;
          }
          .feather,
          img {
            width: 16px;
            font-size: 16px;
            color: $extra-light-color;
          }
          span {
            display: inline-block;
            color: rgba(255, 255, 255, 0.65);
            transition: 0.3s ease;
            a {
              /* Sidebar menu without items -- old may remove */
              padding-left: 15px;
              color: rgba(255, 255, 255, 0.65);
            }
          }
          .sDash_menu-item-icon {
            line-height: 0.6;
          }
        }
        .ant-menu-item {
          /* Sidebar menu without items */
          .ant-menu-title-content {
            padding-left: 15px;
            span {
              padding-left: 20px;
            }
          }
        }
        .ant-menu-submenu {
          // Sidebar with items
          .ant-menu-title-content {
            padding-left: 15px;
            span {
              padding-left: 20px;
            }
          }
        }
        .ant-menu-item {
          .menuItem-icon {
            width: auto;
          }
        }
        .ant-menu-item,
        .ant-menu-submenu-title {
          a {
            position: relative;
          }
          > span {
            /* width: 100%; */
            display: flex;
            align-items: center;
            .pl-0 {
              padding-left: 0px;
            }
          }
          .badge {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            height: auto;
            font-size: 10px;
            border-radius: 4px;
            padding: 3px 4px 4px;
            line-height: 1;
            letter-spacing: 1px;
            color: #fff;
            &.badge-primary {
              background-color: $primary-color;
            }
            &.badge-success {
              background-color: $success-color;
            }
          }
        }
        .ant-menu-submenu {
          .ant-menu-submenu-title {
            display: flex;
            align-items: center;
            .title {
              padding-left: 0;
            }
            .badge {
              right: 45px;
            }
          }
        }
        .ant-menu-submenu-inline {
          > .ant-menu-submenu-title {
            display: flex;
            align-items: center;
            padding: 0 15px !important;
            svg,
            img {
              width: 16px;
              height: 16px;
            }

            .ant-menu-submenu-arrow {
              right: auto;
              right: 24px;
              &:after,
              &:before {
                width: 7px;
                background: #868eae;
                height: 1.25px;
              }
              transform: translateY(2px);
              &:before {
                transform: rotate(45deg) translateX(-3.3px);
              }
              &:after {
                transform: rotate(-45deg) translateX(3.3px);
              }
            }
          }
          &.ant-menu-submenu-open {
            > .ant-menu-submenu-title {
              .ant-menu-submenu-arrow {
                &:before {
                  transform: rotate(45deg) translateY(-3.3px);
                }
                &:after {
                  transform: rotate(-45deg) translateY(-3.3px);
                }
              }
            }
          }
          // .ant-menu-submenu-open.ant-menu-submenu-active {
          //   background-color: transparent;
          // }
          .ant-menu-item {
            padding-left: 0px !important;
            padding-right: 0 !important;
            transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
            a {
              // Inner item for submenu
              padding-left: 36px !important;
            }
          }
        }
        .ant-menu-item {
          display: flex;
          align-items: center;
          padding: 0 15px !important;
          &.ant-menu-item-active {
            border-radius: 4px;
            a {
              color: #fff !important;
            }
            // background-color: rgba(255, 255, 255, 0.05);
          }
          a {
            width: 100%;
            display: flex !important;
            align-items: center;
            .feather {
              width: 16px;
              color: $extra-light-color;
            }
            span {
              padding-left: 20px;
              display: inline-block;
              color: $dark-color;
            }
          }
          &.ant-menu-item-selected {
            svg,
            i {
              color: $primary-color;
            }
          }
        }
        .ant-menu-submenu,
        .ant-menu-item {
          &.ant-menu-item-selected {
            border-radius: 4px;
            &:after {
              content: none;
            }
          }
          &.ant-menu-submenu-active {
            // opacity: 0.5;
            // border-radius: 4px;
            // background-color: rgba(255, 255, 255, 0.05);
            .ant-menu-submenu-title .ant-menu-title-content * {
              color: #fff !important;
            }
          }
        }
        .ant-menu-submenu-vertical.ant-menu-submenu-selected {
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 0.2);
          .ant-menu-title-content .feather {
            color: #fff !important;
          }
        }
        .sidebar-nav-title {
          margin-top: 40px;
          margin-bottom: 24px;
        }
        &.ant-menu-inline-collapsed {
          .ant-menu-submenu {
            text-align: left;
            .ant-menu-submenu-title {
              padding: 0 20px;
              justify-content: center;
            }
          }
          .ant-menu-item {
            padding: 0 8px !important;
            justify-content: center;
            .ant-menu-title-content {
              padding-left: 0;
            }
          }
          .ant-menu-submenu,
          .ant-menu-item {
            span {
              display: none;
            }
          }
        }
      }
    }
    .sidebar-nav-title {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.38);
      padding: 0 15px;
      display: flex;
    }
    &.ant-layout-sider-collapsed {
      // padding: 15px 0px 55px !important;
      .sidebar-nav-title {
        display: none;
      }
      & + .atbd-main-layout {
        margin-left: 80px;
      }
      .ant-menu-item {
        color: #333;
        .badge {
          display: none;
        }
      }
      .ant-menu-submenu {
        .ant-menu-sub {
          opacity: 0;
        }
      }
      .ant-menu-submenu {
        span {
          padding-left: 0 !important;
        }
      }
      // .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed .ant-menu-item span {
      //   display: block;
      // }
      .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
        width: 80px;
        .ant-menu-item,
        .ant-menu-submenu-title {
          .ant-menu-title-content {
            display: flex;
            align-items: center;
            a {
              padding-left: 0;
            }
            span {
              display: none;
            }
          }
          .ant-menu-submenu {
            text-align: left;
            .ant-menu-submenu-title {
              padding: 0 20px;
              justify-content: center;
            }
          }
          .ant-menu-item {
            padding: 0 8px !important;
            justify-content: center;
          }
        }

        .ant-menu-submenu,
        .ant-menu-item {
          span {
            display: none;
          }
        }
      }
    }
    .ant-menu-item-group {
      .ant-menu-item-group-title {
        padding: 0;
      }
    }
    .side-menu-wrapper {
      padding: 0;
      .campaign {
        text-transform: capitalize;
        .ant-menu-title-content {
          padding-right: 17px;
        }
      }
      .campaign-nonSelected {
        background-color: transparent !important;
        opacity: 0.6;
      }
    }
  }

  .account-wrapper {
    padding: 15px;
    &.collapsed {
      padding: 0 10px;
      margin: 0;
      .account-card {
        background-color: transparent;
        margin: 0 !important;
        .ant-card-body {
          padding: 0 !important;
        }
        .acc-text {
          display: none;
        }
        .avatar {
          padding: 0;
          margin-top: 30px;
          margin-bottom: 10px;
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }
  .usage-wrapper {
    padding: 30px;
    .usage-card {
      background: rgba(255, 255, 255, 0.05) !important;
      color: #fff;
      .ant-card-body {
        padding: 20px !important;
        white-space: nowrap;
        .text-bold {
          font-weight: 500;
        }
        .text-light {
          font-weight: 400;
          font-size: 12px;
          opacity: 0.6;
        }
        .icon-row {
          line-height: 18px;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          .icon {
            line-height: 14px;
            margin-right: 8px;
          }
        }
        .row2 {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 8px;
        }
        .row3 {
          line-height: 20px;
          margin-bottom: 4px;
          .text-bold {
            font-size: 16px;
          }
        }
        .row4 {
          line-height: 16px;
          margin-bottom: 20px;
        }
        .row5 {
          display: flex;
          align-items: center;
          cursor: pointer;
          .icon {
            line-height: 16px;
            margin-left: 4px;
          }
        }
      }
    }
    &.collapsed {
      padding: 0 10px;
      margin: 0;
      .usage-card {
        margin: 16px 0 0 0 !important;
        background: transparent !important;
        color: rgba(255, 255, 255, 0.65) !important;
        .ant-card-body {
          padding: 0 !important;
          .icon-row {
            justify-content: center;
            .icon {
              margin: 0;
              color: #adb4d2 !important;
            }
          }
        }
      }
    }
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub,
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
    background-color: #1c273c !important;
  }
  .ant-menu-inline,
  .ant-menu-inline-collapsed {
    background-color: #1c273c !important;
    padding: 0 17px;
    .ant-menu-item-selected {
      background-color: rgba(255, 255, 255, 0.05) !important;
      svg,
      i {
        color: #fff !important;
      }
      span {
        color: #fff !important;
      }
    }
  }
  .ant-menu-inline.ant-menu-sub {
    .ant-menu-item {
      .ant-menu-title-content {
        padding-left: 48px !important;
      }
    }

    // .ant-menu-item.campaign {
    //   .ant-menu-title-content {
    //     padding-left: unset !important;
    //   }
    // }
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item-selected {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
    .ant-menu-item a {
      align-items: center;
      justify-content: center;
    }
    .ant-menu-submenu .ant-menu-submenu-title {
      padding: 0 !important;
    }
  }
  .ant-menu-sub {
    background: #1c283b !important;
    padding: 0;
  }
  .ant-menu-item-selected .router-link-active.active {
    color: #fff !important;
  }
  .ant-menu-inline .ant-menu-submenu-selected .ant-menu-submenu-title * {
    color: #fff !important;
  }
  .ant-menu-inline .ant-menu-submenu-selected .ant-menu-submenu-title {
    .ant-menu-submenu-arrow:before,
    .ant-menu-submenu-arrow:after {
      background: #fff !important;
    }
  }
  .ant-menu-inline .ant-menu-item.ant-menu-item-only-child .router-link-active.active,
  .ant-menu-vertical .router-link-active.active {
    .feather,
    span {
      color: #fff !important;
    }
  }
  .side-menu-footer {
    color: #adb4d2;
    padding: 30px;
    font-size: 12px;
    line-height: 16px;
    .version-tag {
      margin: 0;
    }
    .normal-footer {
      opacity: 1;
      transition: height 0.2s ease, opacity 0.3s ease;
    }
    .link-row {
      margin-top: 8px;
    }
    .footer-icon {
      margin-top: 32px;
    }
    .version-row,
    .link-row {
      white-space: nowrap;
      overflow: hidden;
    }

    &.collapsed {
      display: flex;
      flex-direction: column;
    }
  }
  .menu-title {
    padding: 0 31px !important;
    margin-bottom: 0;
    white-space: nowrap;
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    background-color: #182133;
    padding-left: 15px;
    flex-basis: 280px;
    max-width: 100%;
    transition: all 0.2s;
    min-height: 64px;
    .dom_logo {
      height: 24px;
      width: auto !important;
      margin-left: 10px;
      transition: opacity 0.2s ease;
    }
    &.collapsed {
      overflow: hidden !important;
      max-width: 80px !important;
      flex-basis: 80px;
      min-height: 64px;
      .dom_logo {
        opacity: 0;
      }
    }
  }
  .usage-tooltip {
    left: 67px !important;
  }
  .user-col {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
  }
  .order-badge .ant-badge-count {
    background: #3371ff !important;
  }
  .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &.is-fixed {
    position: fixed;
    right: left;
    z-index: 2;
  }

  .ant-menu-title-content {
    display: inline-block !important;
    text-overflow: ellipsis;
  }
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
  left: 72px !important;
  .ant-tooltip-inner {
    display: flex;
    align-items: center;
    i {
      margin-right: 8px;
    }
  }
}
.account-card {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  font-weight: 500;
  margin-top: 13px;
  margin-bottom: 12px;
  .acc-text {
    text-transform: uppercase;
    transition: all 0.5s;
  }
  .ant-card-body {
    padding: 16px !important;
    white-space: nowrap;
    width: 100%;
    .acccount-card-body {
      display: flex;
      align-items: center;
    }
  }
  .avatar {
    height: 40px;
    width: 40px;
    margin-right: 8px;
    min-width: 40px;
  }
  &.light {
    background-color: #f0f2f5;
    .acc-text {
      color: #272b41;
      text-transform: none;
    }
    .role-text {
      color: #9299b8;
    }
  }
}
.user-menu {
  .ant-dropdown-menu-item li {
    padding: 10px 15px;
  }
  .account-item-row {
    padding: 10px !important;
    position: relative;
    .account-card {
      margin: 0;
    }
  }
  &.ant-dropdown-menu {
    min-width: 300px;
    padding: 0;
    border-radius: 0 0 8px 8px;
    filter: drop-shadow(0px 5px 30px rgba(146, 153, 184, 0.149));
    li {
      padding-left: 0;
      a {
        padding-left: 20px;
      }
      &:hover {
        background-color: rgba(9, 124, 255, 0.05);
        a {
          color: #3371ff;
        }
      }
      &:last-child {
        background: #f8f9fb;
        border-radius: 0 0 8px 8px;
        padding-left: 25px;
        a {
          justify-content: center;
        }
      }
    }
  }
}
.user-influencer-menu {
  .ant-dropdown-menu-item li {
    padding: 10px 15px;
  }
  &.ant-dropdown-menu {
    min-width: 220px;
    padding: 0;
    border-radius: 0 0 8px 8px;
    li {
      padding-left: 0;
      a {
        padding-left: 20px;
      }
      &:hover {
        background-color: rgba(9, 124, 255, 0.05);
        a {
          color: #3371ff;
        }
      }
    }
  }
}
.usage-tooltip {
  left: 72px !important;
}

#main-content {
  position: relative;
  // overflow-y: auto;
  overflow: hidden;
  &.small-screen {
    padding-left: 74px;
  }
  // @media only screen and (min-width: 1920px) {
  //   padding: 0 180px;
  // }
}
#app-overlay {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: rgba(16, 18, 33, 0.3);
  z-index: 3;
}

#sidebar-overlay {
  position: absolute;
  overflow: hidden;
  height: 100%;
  right: 0;
  width: 100%;
  background-color: rgba(16, 18, 33, 0.3);
  z-index: 2;
}

.user-menu-item-wrapper {
  padding-left: 18px !important;
  &:hover {
    padding-left: 26px !important;
    background-color: rgba(9, 124, 255, 0.05) !important;
    .user-menu-item {
      color: #3371ff !important;
      font-weight: 500 !important;
    }
  }
  .user-menu-item {
    padding-left: 0 !important;
  }
}

.logout-btn {
  text-align: center;
  background-color: #f8f9fb;
}

.to-top-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.campaign-active svg {
  fill: #20c997;
  stroke: #20c997;
}

.ant-menu-item.campaign-nonSelected .ant-menu-title-content .campaign-active svg,
.ant-menu-item-selected.campaign-nonSelected .ant-menu-title-content .campaign-active svg {
  fill: #36d9be;
  stroke: #36d9be;
}

.campaign-inactive svg {
  stroke: #adb4d2;
}
.campaign-active,
.campaign-inactive {
  padding-left: 17px;
  padding-right: 17px;
}
</style>
<style scoped>
:deep(#main-sidebar.is-fixed.ant-layout-sider.ant-layout-sider-collapsed
    .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed
    .ant-menu-submenu-title
    .ant-menu-title-content) {
  line-height: 1;
}
:deep(#main-sidebar.is-fixed.ant-layout-sider
    .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed
    .ant-menu-submenu
    .ant-menu-submenu-title) {
  margin: 0;
}
:deep(#main-sidebar.is-fixed.ant-layout-sider
    .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed
    .ant-menu-submenu
    .ant-menu-submenu-title) {
  width: 46px;
  height: 46px;
}
:deep(#main-sidebar.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu i),
:deep(#main-sidebar.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item i) {
  vertical-align: middle;
}
:deep(.ant-menu-item-selected.campaign-nonSelected) {
  background-color: transparent !important;
  opacity: 0.6;
}
</style>
